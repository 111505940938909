import moment from 'moment';

export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ');
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split('&');
  searchArr.forEach((v) => {
    const index = v.indexOf('=');
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

export function setTitle(title) {
  let data = JSON.stringify({ type: 'setTitle', body: { title: title } });
  try {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.webkit.messageHandlers.NeolixWebView.postMessage(data);
    } else if (/(Android)/i.test(navigator.userAgent)) {
      window.NeolixWebView.postMessage(data);
    }
  } catch (error) {
    // console.log(`title-${data}`);
  }
}
export function requestCheckHailVehicle() {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'checkHailVehicle');
  } catch (error) {
    //
  }
}

export function requestCameraPermission() {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'cameraPermission');
  } catch (error) {
    //
  }
}

export function splashFinish() {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'splashFinish');
  } catch (error) {
    //
  }
}

export function requestMicroPermission() {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'microphonePermission');
  } catch (error) {
    //
  }
}

export function requestLocationPermission() {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'requestLocationPermission');
  } catch (error) {
    //
  }
}

export function modifyDeliveryStation(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'modifyDeliveryStation', data);
  } catch (error) {
    //
  }
}
// 站点任务概览点击"筛选"按钮跳转原生页面
export function selectAreaData(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'selectAreaData', data);
  } catch (error) {
    //
  }
}
// 站点任务概览点击"叫车"按钮跳转原生页面
export function taskViewHailVehicle(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'taskViewHailVehicle', data);
  } catch (error) {
    //
  }
}
// 叫车成功后通知客户端,站点任务概览进行刷新
export function carHailingSuccessFlag(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'carHailingSuccessFlag', data);
  } catch (error) {
    //
  }
}
// 点击到站详情的车辆跳转车辆详情
export function gotoVehicleDetail(vin, online) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'gotoVehicleDetail', vin, online);
  } catch (error) {
    //
  }
}
// 地图标绘,存储数据信息
export function setLogisticsMapLocalStorage(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'LogisticsMapLocalStorage', data);
  } catch (error) {
    //
  }
}
// webview-获取经纬度
export function getLocation() {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'getLocation');
  } catch (error) {
    //
  }
}
// 给原生传值缓存数据,场景:数据中心,智能驾驶管理
export function setLocalStorageDataByApp(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', data);
  } catch (error) {
    //
  }
}
// 工单列表点击”故障诊断“跳转诊断页面
export function faultDiagnosis(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'faultDiagnosis', data);
  } catch (error) {
    //
  }
}
// 工单列表点击”查看详情“跳转详情页面
export function faultDiagnosisDetail(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'faultDiagnosisDetail', data);
  } catch (error) {
    //
  }
}
// 故障诊断成功后通知客户端,工单列表进行刷新
export function diagnosisSuccessFlag(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'diagnosisSuccessFlag', data);
  } catch (error) {
    //
  }
}
// 路线生成，通知原生在kill的时候，是否调用回退方法
export function setPoiCalibrationFailWhenKill(data) {
  try {
    window.flutter_inappwebview.callHandler('NeolixWebView', 'setPoiCalibrationFailWhenKill', data);
  } catch (error) {
    //
  }
}
/**
 * 无网络
 */
export function neterrorPage() {
  let data = JSON.stringify({ type: 'neterrorPage' });
  try {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.webkit.messageHandlers.NeolixWebView.postMessage(data);
    } else if (/(Android)/i.test(navigator.userAgent)) {
      window.NeolixWebView.postMessage(data);
    }
  } catch (error) {
    console.log(`neterrorPage error`);
  }
}

// 设置暗黑主题
export function setThemes(mode) {
  let themes = mode === '2' ? 'dark' : 'light';
  window.document.documentElement.setAttribute('data-theme', themes);
}

// js字符串转日期Date
// 字符串格式：2020-10-13 12:00:01
export function stringToDate(strDate) {
  var tempStrs = strDate.split(' ');
  var dateStrs = tempStrs[0].split('/');
  var year = parseInt(dateStrs[0], 10);
  var month = parseInt(dateStrs[1], 10) - 1;
  var day = parseInt(dateStrs[2], 10);

  var timeStrs = tempStrs[1].split(':');
  var hour = parseInt(timeStrs[0], 10);
  var minute = parseInt(timeStrs[1], 10);
  var second = parseInt(timeStrs[2], 10);

  var date = new Date(year, month, day, hour, minute, second);
  return date;
}

// js日期Date格式化为字符串
// 字符串格式：2020-10-13 12:00:01
export function format(date, fmt) {
  var o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
    }
  }
  return fmt;
}

// 计算贝两点经纬度偏移
export function getArcMidPoint(lngOne, lngTwo, latOne, latTwo) {
  let t, t2, h, h3, lng3, lat3;
  let lng1 = Number(lngOne),
    lng2 = Number(lngTwo),
    lat1 = Number(latOne),
    lat2 = Number(latTwo);

  if (lng2 > lng1) {
    if (lng2 - lng1 > 180) {
      if (lng1 < 0) {
        lng1 = 180 + 180 + lng1;
      }
    }
  }
  if (lng1 > lng2) {
    if (lng1 - lng2 > 180) {
      if (lng2 < 0) {
        lng2 = 180 + 180 + lng2;
      }
    }
  }
  if (lat2 == lat1) {
    t = 0;
    h = lng1 - lng2;
  } else {
    if (lng2 == lng1) {
      t = Math.PI / 2;
      h = lat1 - lat2;
    } else {
      t = Math.atan((lat2 - lat1) / (lng2 - lng1));
      h = (lat2 - lat1) / Math.sin(t);
    }
  }
  t2 = t + Math.PI / 7;
  h3 = h / 2;
  lng3 = h3 * Math.cos(t2) + lng1;
  lat3 = h3 * Math.sin(t2) + lat1;
  return [lng3, lat3];
}

// 两点之间计算经纬度
export function getCenterLonLat(oneLon, oneLat, twoLon, twoLat) {
  //oneLon：第一个点的经度；oneLat：第一个点的纬度；twoLon：第二个点的经度；twoLat：第二个点的纬度；
  let aLon = 0,
    aLat = 0;
  let bLon = Number(oneLon) - Number(twoLon);
  let bLat = Number(oneLat) - Number(twoLat);
  //Math.abs()绝对值
  if (bLon > 0) {
    aLon = Number(oneLon) - Math.abs(bLon) / 2;
  } else {
    aLon = Number(twoLon) - Math.abs(bLon) / 2;
  }
  if (bLat > 0) {
    aLat = Number(oneLat) - Math.abs(bLat) / 2;
  } else {
    aLat = Number(twoLat) - Math.abs(bLat) / 2;
  }
  return [aLon, aLat];
}

// 获取指定日期当周的一周日期
export function getWeekTime(date = new Date(), step = 7) {
  let new_Date = date;
  let timesStamp = new_Date.getTime();
  let currenDay = new_Date.getDay();
  let dates = [];
  for (let i = 0; i < step; i++) {
    let das = new Date(
      timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7))
    ).toLocaleDateString();
    das.replace(/[年月]/g, '.').replace(/[日上下午]/g, '');
    dates.push(new Date(das));
  }
  return dates;
}
// js获取距离当天时间n天的日期
export function getDateByDate(date = new Date(), range = 0) {
  var date2 = new Date(date);
  date2.setDate(date2.getDate() + range);
  return date2;
}

// 获取当前日期的上一个月
const monthArr = [
  '一月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '十一月',
  '十二月',
];
export function getLastMonth(date) {
  // 当前月的日期
  let nowDate = date.getDate();
  let lastMonth = new Date(date.getTime());
  // 设置上一个月（这里不需要减1）
  lastMonth.setMonth(lastMonth.getMonth());
  // 设置为0，默认为当前月的最后一天
  lastMonth.setDate(0);
  // 上一个月的天数
  let daysOflastMonth = lastMonth.getDate();
  // 设置上一个月的日期，如果当前月的日期大于上个月的总天数，则为最后一天
  lastMonth.setDate(nowDate > daysOflastMonth ? daysOflastMonth : nowDate);
  // const year = lastMonth.getFullYear()
  // let month = lastMonth.getMonth()
  return {
    date: moment(lastMonth).format('YYYY-MM'),
    dateFormat: moment(lastMonth).format('YYYY年MM月'),
    dateXAxisFormat: moment(lastMonth).format('YYYY.MM'), //`${year}年\n${monthArr[month]}`,    // 适配x周坐标显示
  };
}
//  日报X轴数据
export function setDayXAxisData(currentDayDate) {
  let arr = [];
  for (let i = 0; i < 7; i++) {
    let before = getDateByDate(currentDayDate, -i);
    arr.push(moment(before).format('MM.DD'));
  }
  return arr;
}
//  周报X轴数据
export function setWeekXAxisData(currentWeekDate) {
  const endDay = currentWeekDate[currentWeekDate.length - 1];
  let arr = [];
  for (let i = 0; i < 28; i = i + 7) {
    let star = getDateByDate(endDay, -(i + 6));
    let end = getDateByDate(endDay, -i);
    let axis = `${moment(star).format('MM.DD')}~${moment(end).format('MM.DD')}`;
    // if (star.getMonth() === end.getMonth()) {
    //   axis = `${moment(star).format('MM月')}\n${moment(star).format('DD日')}-${moment(end).format('DD日')}`;
    // } else {
    //   axis = `${moment(star).format('MM月DD日')}\n-${moment(end).format('MM月DD日')}`;
    // }
    arr.push(axis);
  }
  return arr;
}
//  月报X轴数据
export function setMonthXAxisData(currentMonth, type = 'dateXAxisFormat') {
  let tempMonth = currentMonth;
  let arr = [tempMonth[type]];
  for (let i = 0; i < 5; i++) {
    tempMonth = getLastMonth(new Date(tempMonth.date));
    arr.push(tempMonth[type]);
  }
  return arr;
}
/***
 * 四舍五入保留2位小数（若第二位小数为0，则保留一位小数）
 */
export const keepTwoDecimal = (num) => {
  var result = parseFloat(num);
  if (isNaN(result)) {
    // alert('传递参数错误，请检查！');
    return '--';
  }
  result = Math.round(num * 100) / 100;
  return result;
};

// 防抖函数

export const debounce = (callback, delay) => {
  let timer = null;
  return function () {
    // 因为这里返回出去的方法是被 外部元素调用的,
    // 所以这里的 this 就指向了外部元素
    let self = this;
    // 每个函数内部都一个属性 arguments, 其中包括了所有外部调用这个函数传递进来的参数
    let args = arguments;
    timer && clearTimeout(timer);
    timer = setTimeout(function () {
      // apply: 作用就是改变方法内部this的指向, 并能将参数传递给该方法, 最后立即执行这个函数
      callback.apply(self, args);
    }, delay);
  };
};
// 根据两个坐标点，计算出两点之间的角度
export const getAngle = (position1, position2) => {
  let valLng = position1.lng - position2.lng;
  let valLat = position1.lat - position2.lat;

  // 返回一个绝对值
  let absoluteLng = Math.abs(valLng);
  let absoluteLat = Math.abs(valLat);

  // 取得三角形的斜边
  let hypotenuse = Math.hypot(absoluteLng, absoluteLat);
  // 计算弧度
  let radina = Math.acos(absoluteLng / hypotenuse);

  // 计算角度
  let angle = Math.floor((radina * 180) / Math.PI);

  // 计算出标记点真正旋转的角度
  if (valLng > 0) {
    if (valLat > 0) {
      // 第三象限
      angle = 360 - angle;
    } else {
      // 第二象限
      angle = angle;
    }
  } else {
    if (valLat > 0) {
      //第四象限
      angle = 180 + angle;
    } else {
      // 第一象限
      angle = 180 - angle;
    }
  }
  return angle;
};

export const getYaw = (e) => {
  const c_1 = e[0];
  const c_2 = e[1];
  let yaw = Math.atan((c_2[0] - c_1[0]) / (c_2[1] - c_1[1]));
  if (c_2[0] > c_1[0] && c_2[1] < c_1[1]) {
    yaw = yaw + Math.PI;
  } else if (c_2[0] < c_1[0] && c_2[1] < c_1[1]) {
    yaw = yaw - Math.PI;
  }
  return yaw;
};

export const isIOS = () => /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);

// 数组去重,数组中的元素是对象
export const uniqueByContent = (array, key) => {
  if (!array) return [];
  const map = new Map();
  return array.filter((item) => {
    const isNew = !map.has(key(item));
    map.set(key(item), item);
    return isNew;
  });
};
export const createHeading = (q) => {
  if (Array.isArray(q)) {
    const siny_cosp = 2 * (q[0] * q[3] + q[1] * q[2]);
    const cosy_cosp = 1 - 2 * (q[2] * q[2] + q[3] * q[3]);
    const yaw = -Math.atan2(siny_cosp, cosy_cosp);
    return yaw;
  } else if (typeof q === 'number') {
    return q;
  } else {
    return [];
  }
};
