/*
 * @Author: zhang flstack@163.com
 * @Date: 2025-03-28 19:05:55
 * @LastEditors: zhang flstack@163.com
 * @LastEditTime: 2025-03-28 19:51:08
 * @FilePath: /neolixapph5/src/locales/en.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  on: 'on',
  off: 'off',
  cancel: 'cancel',
  confirm: 'confirm',
  noData: 'No data available at the moment',
  lightControl: {
    title: 'Lighting control',
    night: 'night',
    day: 'day',
    warning: 'currently in standby mode, the light control cannot be operated',
    Toast: 'The vehicle is in standby mode and the lights cannot be controlled',
    common: {
      title: 'common',
      close: 'off',
      LowBeam: 'Low beam',
      highBeam: 'high beam',
    },
    other: {
      title: 'other',
      close: 'off',
      positionLight: 'position light',
      DaytimeRunningLight: 'daytime Running Light',
      fogLamp: 'fog lamp',
    },
    atmosphereLights: {
      title: 'atmosphere lights',
      close: 'off',
      white: 'white',
      bluishGreen: 'bluish green',
      blue: 'blue',
      red: 'red',
      sevenRevising: 'seven revising',
    },
    modeDecision: {
      title: 'mode decision',
      Light: 'Light',
      flicker: 'flicker',
      breathe: 'breathe',
    },
    prompt: {
      title: 'prompt',
      message1: 'Are you sure to turn off all commonly used lights?',
      message2:
        'If you want to turn off the low beam, the high beam will also turn off at the same time',
      message2_1: 'Are you sure to turn on the low beam?',
      message2_2: 'Are you sure to turn off the low beam?',
      message3:
        'If you want to turn on the high beam, the low beam will also turn on at the same time',
      message3_1: 'Are you sure to turn on the high beam?',
      message3_2: 'Are you sure to turn off the high beam?',
      message4: 'Are you sure to turn off all other lights?',
      message4_1: 'Are you sure to turn on the side lights?',
      message4_2: 'Are you sure to turn off the position lights?',
      message5_1: 'Are you sure to turn on the daytime running lights?',
      message5_2: 'Are you sure to turn off the daytime running lights?',
      message6_1: 'Are you sure to turn on the fog lights?',
      message6_2: 'Are you sure to turn off the fog lights?',
    },
  },
  voiceInteraction: {
    title: 'voice interaction',
    volume: 'volume',
    VoiceAnnouncements: 'voice \nannouncements',
    whistle: 'whistle',
    inchAdvertisingScreen: '49 inch advertising screen',
    theWhistle: 'The horn has been sounded',
    AdvertisingScreenStatus: 'Advertising screen status',
    oninstruction: 'The activation command has been issued',
    offinstruction: 'The close command has been issued',
  },
  selectVoice: {
    title: 'Select voice',
    stopVoice: 'Pause playback',
    chooseVoice: 'Please select the playback frequency',
    loop: 'Loop Playback',
    once: 'Play Once',
    VoicePlayback: 'Playing voice',
    stopPlay: 'The voice has stopped playing',
    confirmStopPlay: 'Are you sure to pause playing this voice?',
  },
  intelligentSecurity: {
    title: 'intelligent security',
    listArr1: 'Police light control',
    listArr2: 'Container control',
    listArr3: 'Side screen control',
    listArr4: 'Remote shouting',
    listArr5: 'Volume control',
    offTip: 'Close successfully',
    onTip: 'Successfully opened',
    volume: 'volume',
    volumeSuccess: 'volume adjustment successful',
    longShout: 'Please press and hold the button to call out, release to end the call',
  },
};
