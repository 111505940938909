export default {
  on: '开启',
  off: '关闭',
  cancel: '取消',
  confirm: '确定',
  noData: '暂无数据',
  lightControl: {
    title: '灯光控制',
    night: '晚上',
    day: '白天',
    warning: '当前处于待机模式，灯光控制无法操作',
    Toast: '车辆处于待机状态，不可控制灯光',
    common: {
      title: '常用',
      close: '关闭',
      LowBeam: '近光灯',
      highBeam: '远光灯',
    },
    other: {
      title: '其他',
      close: '关闭',
      positionLight: '示廓灯',
      DaytimeRunningLight: '昼行灯',
      fogLamp: '雾灯',
    },
    atmosphereLights: {
      title: '氛围灯',
      close: '关闭',
      white: '白色',
      bluishGreen: '蓝绿色',
      blue: '蓝色',
      red: '红色',
      sevenRevising: '七彩色',
    },
    modeDecision: {
      title: '模式选择',
      Light: '常亮',
      flicker: '闪烁',
      breathe: '呼吸',
    },
    prompt: {
      title: '提示',
      message1: '确认关闭所有常用灯光吗？',
      message2: '如要关闭近光灯，远光灯也将同时关闭',
      message2_1: '确认开启近光灯吗？',
      message2_2: '确认关闭近光灯吗？',
      message3: '如要打开远光灯，近光灯也将同时打开',
      message3_1: '确认开启远光灯吗？',
      message3_2: '确认关闭远光灯吗？',
      message4: '确认关闭所有其他灯光吗？',
      message4_1: '确认开启示廓灯吗？',
      message4_2: '确认关闭示廓灯吗？',
      message5_1: '确认开启昼行灯吗？',
      message5_2: '确认关闭昼行灯吗？',
      message6_1: '确认开启雾灯吗？',
      message6_2: '确认关闭雾灯吗？',
    },
  },
  voiceInteraction: {
    title: '语音互动',
    volume: '音量',
    VoiceAnnouncements: '语音播报',
    whistle: '鸣笛',
    inchAdvertisingScreen: '49寸广告屏',
    theWhistle: '已鸣笛',
    AdvertisingScreenStatus: '广告屏状态',
    oninstruction: '开启指令已下发',
    offinstruction: '关闭指令已下发',
  },
  selectVoice: {
    title: '选择语音',
    stopVoice: '暂停播放',
    chooseVoice: '请选择播放频率',
    loop: '循环播放',
    once: '播放一次',
    VoicePlayback: '正在播放语音',
    stopPlay: '语音已停止播放',
    confirmStopPlay: '确定暂停播放该语音吗？',
  },
  intelligentSecurity: {
    title: '智能安防',
    listArr1: '警灯控制',
    listArr2: '货箱控制',
    listArr3: '侧屏控制',
    listArr4: '远程喊话',
    listArr5: '音量控制',
    offTip: '关闭成功',
    onTip: '开启成功',
    volume: '音量',
    volumeSuccess: '音量调节成功',
    longShout: '请长按按键进行喊话，松开结束喊话',
  },
};
