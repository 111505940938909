/*
 * @Author: zhang flstack@163.com
 * @Date: 2025-03-28 12:29:34
 * @LastEditors: zhang flstack@163.com
 * @LastEditTime: 2025-03-28 14:10:13
 * @FilePath: /neolixapph5/src/i18n.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// 使用方法
// $t('lightControl.title')
// this.$t('lightControl.title')

import Vue from 'vue';
import VueI18n from 'vue-i18n';

// 引入语言文件
import en from './locales/en.js';
import zh from './locales/zh.js';

// 使用 VueI18n 插件
Vue.use(VueI18n);

// 创建 VueI18n 实例
const i18n = new VueI18n({
  locale: 'zh', // 默认语言
  fallbackLocale: 'en', // 设置备用语言
  legacy: false, // 使用组合式API
  globalInjection: true, // 全局注入 $t 函数
  messages: {
    en,
    zh,
  },
});

export default i18n;
